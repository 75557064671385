<template lang="">
    <div>
        <div class="m-3">
            <b-form>
                <b-card  no-body>
                    <b-list-group>
                        <b-list-group-item>
                            <div>ຊື່ ແລະ ນາມສະກຸນ</div>
                            <h5> {{fld.fname}} {{fld.lname}}, ເພດ: {{fld.sex}}</h5>
                        </b-list-group-item>
                        <b-list-group-item>
                            <div>ວັນເດືອນປີເກິດ</div>
                            <h5> {{fld.dob |formatDate}}, ອາຍຸ {{fld.age}} ປີ    </h5>
                        </b-list-group-item>

                        <b-list-group-item>
                            <div>ວັນທີ, ເວລານັດ</div>
                            <h5> {{fld.queue_date|dateString}}  </h5>
                          
                        </b-list-group-item>
                    </b-list-group>
                    
    
                    
                  
    
    
    
                     
                   
              
    
                    
                    <b-row class="mt-3">
                        <b-col>
                          
                         <b-button block  @click="goback" >QR Scan</b-button>
                        </b-col>
                    </b-row> 
    
                </b-card>
            </b-form>
        </div>
    </div>
</template>

<script>
export default {
  methods: {
    goback() {
            this.$router.go(-1)
        },
  },
    created() {},
    data() {
        return {
            fld: {
                fname: "",
                lname: "",
                sex: "",
                dob: "",
                "age": "",
                "ca1": false,
                "ca2": false,
                "ca3": false,
                "ca4": false,
                "ca5": false,
                "ca6": false,
                "ca7": false,
                "ca8": false,
                "ca9": false,
                "ca10": false,
                "ca11": false,
                "ca12": false,
                "ca13": false,
                "ca14": false,
                "ca15": false,
                unit_id: "",
                queue_no: "",
                queue_date: "",
                start_time: "",
                end_time: "",
                idcardexpriry:""

            },
            tf(t) {
                if (t == 1) {
                    return true
                } else {
                    return false
                }
            }
        }
    },
    mounted() {
        let info = this.$route.query.qr
        //   let  qr= [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date,this.fld.start_time,this.fld.end_time, this.fld.ca1, this.fld.ca2, this.fld.ca3, this.fld.ca4, this.fld.ca5, this.fld.ca6, this.fld.ca7, this.fld.ca8, this.fld.ca9, this.fld.ca10]
        let arr = info.split('|')
        this.fld = {
            fname: arr[2],
            lname: arr[3],
            "age": arr[4],
            sex: arr[5],
            dob: arr[6],
            queue_date: arr[7],
            start_time: arr[8],
            end_time: arr[9],
            "ca1": arr[10],
            "ca2": arr[11],
            "ca3": arr[12],
            "ca4": arr[13],
            "ca5": arr[14],
            "ca6": arr[15],
            "ca7": arr[16],
            "ca8": arr[17],
            "ca9": arr[18],
            "ca10": arr[19],
            "ca11": arr[20],
            "ca12": arr[21],
            "ca13": arr[22],
            "ca14": arr[23],
            "ca15": arr[24],
            unit_id: "",
            queue_id: arr[0],
            queue_no: arr[1],

        }
    },
    filters:{
        formatDate(dd) {
            let d = new Date(dd)
            return [
                (d.getDate().toString().padStart(2, '0')),
                (d.getMonth() + 1).toString().padStart(2, '0'),
                d.getFullYear(),
            ].join('/');
        },
        dateString(dd){
            let op={ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
            const d = new Date(dd);
           
            return  d.toLocaleString('lao',op);
        },
    }

}
</script>

<style lang="">

</style>